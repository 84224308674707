import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Grid, Box } from "@material-ui/core";
import DraggableUser from "./DraggableUser";
import { User } from "./types";

interface UnassignedUsersPanelProps {
  title: string;
  id: string;
  users: User[];
}

const UnassignedUsersPanel = ({
  title,
  id,
  users
}: UnassignedUsersPanelProps) => {
  return (
    <Grid>
      <Grid container>
        <Grid item xs={9}>
          <h4>{title}</h4>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}></Grid>
      </Grid>
      <Box
        component="div"
        style={{ overflowY: "auto", overflowX: "hidden" }}
        maxHeight="500px"
      >
        <Droppable droppableId={id}>
          {(provided, snapshot) => (
            <Grid ref={provided.innerRef} {...provided.droppableProps}>
              {users.map((user, index) => (
                <DraggableUser
                  key={user.userId}
                  user={user}
                  index={index}
                  showClearButton={false}
                  isDragDisabled={true}
                />
              ))}
              {provided.placeholder}
              <hr />
            </Grid>
          )}
        </Droppable>
      </Box>
    </Grid>
  );
};

export default UnassignedUsersPanel;
