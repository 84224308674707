import React, { useState, createContext } from "react";

export const SavedFormsContext = createContext<any>([{}, () => {}]);

export const SavedFormsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [savedFormInstallation, setSavedFormInstallation] = useState({});

  return (
    <SavedFormsContext.Provider
      value={{
        savedFormInstallationContext: [
          savedFormInstallation,
          setSavedFormInstallation
        ]
      }}
    >
      {children}
    </SavedFormsContext.Provider>
  );
};
