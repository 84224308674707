import { InstallationDevice, InstallationDevice_Device } from "../types";
import { isNullOrEmpty } from "../../../../../utils/utils";

export class InstallationDevice_DeviceDataModel
  implements InstallationDevice_Device
{
  deviceComputedHash: string = "";
  deviceTwinId: string = "";
  deviceName: string = "";
  deviceType: string = "";

  constructor(model?: InstallationDevice_Device) {
    if (model) {
      this.deviceComputedHash = model.deviceComputedHash;
      this.deviceTwinId = model.deviceTwinId;
      this.deviceName = model.deviceName;
      this.deviceType = model.deviceType;
    }
  }

  public hasValidHashOrTwinId(): boolean {
    return (
      !isNullOrEmpty(this.deviceComputedHash) ||
      !isNullOrEmpty(this.deviceTwinId)
    );
  }
}

export class InstallationItemCheckboxDataModel implements InstallationDevice {
  installationName: string = "";
  installationId: string = "";
  devices: InstallationDevice_DeviceDataModel[] = [];

  public constructor(model?: InstallationDevice) {
    if (model) {
      this.installationName = model.installationName;
      this.installationId = model.installationId;
      this.devices = model.devices as InstallationDevice_DeviceDataModel[];
    }
  }

  private hasInvalidDevicesOnly(): boolean {
    let res = true;
    this.devices.forEach((d) => {
      const deviceDM = new InstallationDevice_DeviceDataModel(d);
      res = res && !deviceDM.hasValidHashOrTwinId();
    });

    return res;
  }

  public hasInvalidDeviceIds(): boolean {
    if (this.devices.length === 0) {
      return true;
    }

    return this.hasInvalidDevicesOnly();
  }
}
