import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { IPaperHeadingProps } from "./type";

const PaperHeading = ({ label }: IPaperHeadingProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      label: {
        color: "#666666",
        textTransform: "uppercase",
        fontWeight: "bold",
        letterSpacing: "2px",
        fontSize: "15px",
      },
    })
  );
  const classes = useStyles();
  return (
    <Typography className={classes.label} variant="overline">
      {label}
    </Typography>
  );
};
export default PaperHeading;
