import React from "react";
import Layout from "layouts";
import { renderRoutes } from "react-router-config";
import { IElementRouteAttribute } from "routes/type";
import { LogsProvider } from "provider/LogsProvider";

const Logs = ({ route }: IElementRouteAttribute) => {
  return (
    <LogsProvider>
      <Layout>
        {renderRoutes(route.routes)}
      </Layout>
    </LogsProvider>
  );
};

export default Logs;
