import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullTypes } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";

import { IType } from "../../components/type";
import { useMsal } from "@azure/msal-react";

export const TypesContext = createContext<any>(null);

export const TypesProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const { instance } = useMsal();
  const [types, setTypes] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getTypes = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(types) || dateTimeRefresh < dateTime) {
      await pullTypes(instance).then((types: React.SetStateAction<never[]>) => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setTypes(types);
      });
    }
  };
  const getTypeId = (typeName: string) => {
    const selectedApp: IType = types.filter(
      (type: IType) => type.typeName === typeName
    )[0];
    if (selectedApp) {
      return selectedApp.id;
    }
    return "";
  };

  return (
    <TypesContext.Provider value={[types, getTypes, getTypeId]}>
      {children}
    </TypesContext.Provider>
  );
};
