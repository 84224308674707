import React from "react";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ITEM_HEIGHT = 48;

const TeamContainerHeader = ({
  title = "",
  escalation,
  accountName = "",
  alertCode = "",
  onSelectOption
}: {
  title?: string;
  escalation?: number;
  accountName?: string;
  alertCode?: string;
  onSelectOption: (e: string) => void;
}) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={11}>
        <h5 data-testid="data-title">{title}</h5>
      </Grid>
      <Grid item xs={1} style={{ textAlign: "right" }}>
        <OptionsMenu
          options={[{ label: "View", value: "edit" }]}
          onSelect={(e) => {
            const val = e.target.getAttribute("value");

            if (val) {
              onSelectOption(val as string);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

const OptionsMenu = ({
  options,
  onSelect
}: {
  options: { label: string; value: string }[];
  onSelect: (e: any) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any) => {
    setAnchorEl(null);
    if (onSelect) {
      onSelect(e);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} onClick={handleClose} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TeamContainerHeader;
