import React, { useState, useEffect } from "react";
import { Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { InstallationDevice } from "../types";
import { InstallationItemCheckboxDataModel } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    margin: "0",
  },
  label: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export const InstallationItemCheckbox = ({
  installation,
  handleChange,
  checked = false,
}: {
  installation: InstallationDevice;
  handleChange: (checked: boolean, key: string) => void;
  checked: boolean;
}) => {
  const classes = useStyles();
  const [stateChecked, setStateChecked] = useState(false);
  const [model, setModel] = useState<InstallationItemCheckboxDataModel>(
    new InstallationItemCheckboxDataModel()
  );

  useEffect(() => {
    setStateChecked(checked);
  }, [checked]);

  useEffect(() => {
    setModel(new InstallationItemCheckboxDataModel(installation));
  }, [installation]);

  return (
    <Paper variant="outlined" square>
      <Tooltip title={model.installationName}>
        <FormControlLabel
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          disabled={true}
          value={model.installationId}
          control={
            <Checkbox
              checked={stateChecked}
              color="primary"
              onChange={(e) => {
                setStateChecked(e.target.checked);
                handleChange(e.target.checked, model.installationId);
              }}
            />
          }
          label={model.installationName}
          labelPlacement="end"
        />
      </Tooltip>
    </Paper>
  );
};

export default InstallationItemCheckbox;
