import { IPublicClientApplication } from "@azure/msal-browser";
import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import {
  CreateTeamDetailsUnionType,
  Team,
  User,
  Account,
} from "pages/Teams/AssignUsersToTeams/types";
import { TeamsProvider } from "provider/TeamsProvider";
import toastr from "toastr";
import { isValidGuid } from "utils/utils";

export interface AccountResult {
  data: Account[];
  nextPage: string;
  previousPage: string;
  totalRows: number;
  totalPages: number;
}

const callToastrError = (errorText = "") =>
  toastr["error"]("Error occured while processing your request!", errorText);

const HEADERS: { [k: string]: string } = {
  "content-type": "application/json",
  accept: "application/json",
};

export const getAccounts = async (
  page = 1,
  limit = 10,
  query = "",
  instance: IPublicClientApplication
) => {
  const searchKey = query === "" ? "" : `&assetname=${query}`;
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/accounts?page=${page}&limit=${limit}${searchKey}`,
    {
      method: "GET",
      headers: HEADERS,
    },
    instance
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      callToastrError("Failed getting accounts");
      return [];
    })
    .then((accounts: any) => {
      if (accounts) {
        return accounts;
      }

      return [];
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

// TODO: Paging?
export const getAccountNames = async (
  accountIds: string[] = [],
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/getallbyaccountandsectiontype`,
    {
      method: "GET",
      headers: HEADERS,
    },
    instance
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      callToastrError("Error getting accounts");
      return [];
    })
    .then(
      (hierachyLabelAndIds: {
        hierarchies: { value: string; label: string; rootId: string }[];
      }) => {
        if (hierachyLabelAndIds.hierarchies.length > 0) {
          return hierachyLabelAndIds.hierarchies.filter(
            (x) => accountIds.includes(x.value) && x.rootId === null
          );
        }

        return [];
      }
    )
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const getUsersByAccountId = async (
  accountId = "",
  instance: IPublicClientApplication
): Promise<User[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.globalUsers}/getuserslistforhierarchybyaccountid/${accountId}`,
    {
      method: "GET",
      headers: HEADERS,
    },
    instance
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      callToastrError("Error getting users");
      return [];
    })
    .then((res) => {
      let users: {
        userId: string;
        primaryEmail: string;
        teamId: string;
      }[] = [];
      if (res?.globalUsers) {
        users = res.globalUsers.map((x: any) => {
          return {
            userId: x.b2CObjectId,
            primaryEmail: x.primaryEmail,
            teamId: "",
          };
        });
      }

      return users.filter((u) => isValidGuid(u.userId)) as User[];
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

// TODO: Paging?
export const getTeamsByAccountId = async (
  accountId = "",
  instance: IPublicClientApplication
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsQuery}/allAccountTeams`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ accountId: accountId }),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return [];
    })
    .then((teams: Team[]) => {
      return getTeamsWithUsers(teams, instance);
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

const getTeamsWithUsers = async (
  teams: Team[] = [],
  instance: IPublicClientApplication
) => {
  const teamsCopy: Team[] = [...teams];

  for (const t of teams) {
    const users: User[] = await getTeamUsers(t.teamId, instance);
    t.users = users;

    const removeItemIndex = teamsCopy.findIndex((tc) => tc.teamId === t.teamId);
    teamsCopy.splice(removeItemIndex, 1);
    teamsCopy.push(t);
  }

  return teamsCopy;
};

const getTeamUsers = (teamId = "", instance: IPublicClientApplication) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsQuery}/TeamMembers`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ teamId: teamId }),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return [];
    })
    .then((users) => {
      return users;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const createTeam = (
  createTeamDetails: {
    [k: string]: CreateTeamDetailsUnionType;
  },
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsCommand}/createteam`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(createTeamDetails),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      callToastrError("Error creating team");
      throw new Error("Failed to create team");
    })
    .then((teamId: string) => {
      toastr["success"]("Team successfully created!");
      return teamId;
    })
    .then(async (teamId: string) => {
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve(teamId);
        }, 1000)
      );

      return teamId;
    });
};

export const changeTeamMember = (
  teamDetails: {
    accountId: string;
    teamId: string;
    users: {
      userId: string;
      primaryEmail: string;
      escalationQueueNumber: number;
    }[];
  },
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsCommand}/changeteammembers`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(teamDetails),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        toastr["success"]("Members successfully updated!");
        return res.ok;
      }

      callToastrError("Failed to update team member");
      return res.ok;
    })
    .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 1000)));
};

export const removeTeam = (
  teamId: string,
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsCommand}/removeteam`,
    {
      method: "DELETE",
      headers: HEADERS,
      body: JSON.stringify({ teamId: teamId }),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        toastr["success"]("Team successfully removed");
        return res.ok;
      }

      callToastrError("Failed to remove team");
      return res.ok;
    })
    .then((x) => new Promise((resolve) => setTimeout(() => resolve(x), 1000)))
    .then((x) => x)
    .catch((e) => {
      callToastrError("Failed to remove team");
      throw new Error(e);
    });
};

export const getInstallationDevices = (
  accountId: string,
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/${accountId}/installationsdevices`,
    {
      method: "GET",
      headers: HEADERS,
    },
    instance
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return [];
    })
    .then((res) => {
      return res.installationsDevices;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const changeDeviceAlertCodes = (
  teamDeviceAlertCodes: {
    teamId: string;
    deviceAlertCodes: { deviceId: string; alertCodes: string[] }[];
  },
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsCommand}/changedevicealertcodes`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(teamDeviceAlertCodes),
    },
    instance,
    true
  )
    .then((res: any): any => {
      if (res.ok) {
        return;
      } else {
        callToastrError("Error changing device alert codes");
        throw new Error(res.statusText);
      }
    })
    .catch((e) => {
      callToastrError("Error changing device alert codes");
      throw new Error(e);
    });
};

export const getTeamDetails = (
  teamId = "",
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsQuery}/getteamdetails`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ teamId: teamId }),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return null;
    })
    .then((team) => {
      return team;
    })
    .catch((e) => {
      callToastrError("Error getting team details");
      throw new Error(e);
    });
};

export const changeTeam = (
  editTeamDetails: {
    [k: string]: CreateTeamDetailsUnionType;
  },
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.teamsCommand}/changeTeam`,
    {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify(editTeamDetails),
    },
    instance,
    true
  )
    .then((res) => {
      if (res.ok) {
        return;
      }

      callToastrError("Error updating team");
      throw new Error("Failed updating team");
    })
    .then(() => {
      toastr["success"]("Team successfully updated!");
      return editTeamDetails.teamId;
    });
};
