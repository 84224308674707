import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import MainBarDashboard from "components/MainBarDashboard";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import { getAccounts } from "../../../actions/teamsActions";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import { useMsal } from "@azure/msal-react";

const TeamList = () => {
  const { instance } = useMsal();
  const [, setRefresh] = useState<number>();
  const [searchKey, setSearchKey] = useState<string>("");
  const breadcrumbs = [{ label: "Teams" }];
  const history = useHistory();

  const onSearch = (keyword: string): void => {
    const searchTerm = keyword.trim();
    setSearchKey(searchTerm);
  };

  const headCells: IHeadCell[] = [
    {
      id: "id",
      disablePadding: false,
      label: "Account ID",
      fieldName: "id",
    },
    {
      id: "assetName",
      disablePadding: false,
      label: "Account",
      fieldName: "assetName",
      isClickable: true,
      onClick: (account) => {
        history.push(
          "/teams/:accountId/assignUsersToTeams".replace(
            ":accountId",
            account.id
          )
        );
      },
      referenceIds: ["id"],
    },
  ];

  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  const getAccountsWrapper = (page = 1, limit = 10, query = "") => {
    return getAccounts(page, limit, query, instance);
  };

  return (
    <MainBarDashboard
      breadcrumbs={breadcrumbs}
      search
      searchPlaceholder="Search Account"
      onSearch={onSearch}
    >
      <EnhancedPaginatedTable
        getData={getAccountsWrapper}
        headCells={headCells}
        query={searchKey}
      />
    </MainBarDashboard>
  );
};

export default TeamList;
