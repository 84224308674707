import React from "react";
import { renderRoutes } from "react-router-config";
import { IElementRouteAttribute } from "routes/type";
import { GenericProvider } from "provider/GenericProvider";
import { ModalProvider } from "provider/ModalProvider";
import GenericLayout from "layouts/GenericLayout";

const Acknowledge = ({ route }: IElementRouteAttribute) => {
  return (
    <ModalProvider>
      <GenericLayout>
        <GenericProvider>{renderRoutes(route.routes)}</GenericProvider>
      </GenericLayout>
    </ModalProvider>
  );
};

export default Acknowledge;
