import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullLanguages } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";
import { useMsal } from "@azure/msal-react";

export const LanguagesContext = createContext<any>(null);

export const LanguagesProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const { instance } = useMsal();
  const [languages, setLanguages] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getLanguages = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(languages) || dateTimeRefresh < dateTime) {
      await pullLanguages(instance).then((languages) => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setLanguages(languages);
      });
    }
  };

  return (
    <LanguagesContext.Provider value={[languages, getLanguages]}>
      {children}
    </LanguagesContext.Provider>
  );
};
