import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import DialogModal from "components/DialogModal";

const LogsList = () => {
  const [refresh, setRefresh] = useState<number>();
  const breadcrumbs = [{ label: "Logs" }];

  const headCells: IHeadCell[] = [
    {
      id: "id",
      disablePadding: false,
      label: "ID",
      fieldName: "id",
      hidden: true,
    },
    {
      id: "timestamp",
      disablePadding: false,
      label: "Timestamp",
      fieldName: "timestamp",
      isClickable: true,
    },
    {
      id: "alertCode",
      disablePadding: false,
      label: "Alert Code",
      fieldName: "alertCode",
    },
    {
      id: "alertDescription",
      disablePadding: false,
      label: "Alert Description",
      fieldName: "alertDescription",
    },
    {
      id: "severity",
      disablePadding: false,
      label: "Severity",
      fieldName: "severity",
    },
    {
      id: "alertState",
      disablePadding: false,
      label: "Alert State",
      fieldName: "alertState",
    },
    {
      id: "deviceId",
      disablePadding: false,
      label: "Device Id",
      fieldName: "deviceId",
    },
    {
      id: "digitalProduct",
      disablePadding: false,
      label: "Digital Product",
      fieldName: "digitalProduct",
    },
    {
      id: "alertFeedback",
      disablePadding: false,
      label: "Alert Feedback",
      fieldName: "alertFeedback",
    },
  ];

  const onSearch = (keyword: string): void => {};
  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  return (
    <MainBarDashboard
      search
      searchPlaceholder="Search Logs"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <EnhancedPaginatedTable
        getData={() => {
          return new Promise((resolve) => {
            resolve([]);
          });
        }}
        headCells={headCells}
      />
    </MainBarDashboard>
  );
};

export default LogsList;
