import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullUnitSystems } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";
import { useMsal } from "@azure/msal-react";

export const UnitSystemsContext = createContext<any>(null);

export const UnitSystemsProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const { instance } = useMsal();
  const [unitSystems, setUnitSystems] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getUnitSystems = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(unitSystems) || dateTimeRefresh < dateTime) {
      await pullUnitSystems(instance).then((unitSystems) => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setUnitSystems(unitSystems);
      });
    }
  };

  return (
    <UnitSystemsContext.Provider value={[unitSystems, getUnitSystems]}>
      {children}
    </UnitSystemsContext.Provider>
  );
};
