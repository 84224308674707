import React from "react";
import Layout from "layouts";
import { renderRoutes } from "react-router-config";
import { IElementRouteAttribute } from "routes/type";
import { TeamsProvider } from "provider/TeamsProvider";

const Logs = ({ route }: IElementRouteAttribute) => {
  return (
    <TeamsProvider>
      <Layout>
        {renderRoutes(route.routes)}
      </Layout>
    </TeamsProvider>
  );
};

export default Logs;
