import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListIcon from "@material-ui/icons/List";
import ListItemText from "@material-ui/core/ListItemText";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import GroupIcon from "@material-ui/icons/Group";
import LockIcon from "@material-ui/icons/Lock";

import {
  LogsPath,
  TeamsPath,
  CodeManagementPath,
  AuthorizationPath,
} from "routes/paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px 16px",
      left: "0 !important",
      margin: "0 !important",
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        "& .MuiListItemText-root": {
          color: theme.palette.background.paper,
        },
      },
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.common.white} !important`,
        "& .MuiListItemText-root": {
          "& span": {
            color: theme.palette.secondary.dark,
            fontWeight: "bold",
          },
        },
      },
    },
    text: {
      color: theme.palette.background.paper,
    },
  })
);

const MainListItems = () => {
  const history = useHistory();
  const classes = useStyles();
  const { url } = useRouteMatch();
  return (
    <div>
      {/* 
      #TODO: Not Implemented yet
      <MenuItem
        className={classes.root}
        selected={url === LogsPath}
        button
        onClick={() => {
          history.push(LogsPath);
        }}
      >
        <ListItemIcon>
          <ListIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Logs" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === CodeManagementPath}
        button
        onClick={() => {
          history.push(CodeManagementPath);
        }}
      >
        <ListItemIcon>
          <DeveloperModeIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Code Management" />
      </MenuItem> */}

      <MenuItem
        className={classes.root}
        selected={url === TeamsPath}
        button
        onClick={() => {
          history.push(TeamsPath);
        }}
      >
        <ListItemIcon>
          <GroupIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Teams" />
      </MenuItem>

      {/* 
      #TODO: Not Implemented yet
      <MenuItem
        className={classes.root}
        selected={url === AuthorizationPath}
        button
        onClick={() => {
          history.push(AuthorizationPath);
        }}
      > 
        <ListItemIcon>
          <LockIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Authorization" />
      </MenuItem>*/}
    </div>
  );
};

export default MainListItems;
