import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const FloatingActionButtonList = ({
  children
}: JSX.ElementChildrenAttribute) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "fixed",
        bottom: "25px",
        right: "20px",
        display: "flex",
        flexDirection: "column"
      }
    })
  );
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default FloatingActionButtonList;
