import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import DialogModal from "components/DialogModal";

const CodeList = () => {
  const [refresh, setRefresh] = useState<number>();
  const breadcrumbs = [{ label: "Code Management" }];

  
  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  return (
    <MainBarDashboard breadcrumbs={breadcrumbs}>
      <h1>Code Management Page</h1>
    </MainBarDashboard>
  );
};

export default CodeList;
