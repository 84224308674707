import React, { createContext } from "react";

export const TeamsContext = createContext<any>([{}, () => {}]);

export const TeamsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  
  return (
    <TeamsContext.Provider value="">
      {children}
    </TeamsContext.Provider>
  );
};