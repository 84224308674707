import React, { useContext, useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { Droppable } from "react-beautiful-dnd";
import { Grid, Paper, Button, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DraggableUser from "../DraggableUser";
import TeamContainerHeader from "./TeamsContainerHeader";
import { CreateTeamDetailsUnionType, Team, Account, User } from "../types";
import { InstallationDevice, TeamsContainer } from "./types";
import DialogModal from "../../../../components/DialogModal";
import CreateUpdateTeam from "./CreateUpdateTeam/CreateUpdateTeam";
import {
  createTeam,
  removeTeam,
  changeDeviceAlertCodes,
  changeTeam,
  changeTeamMember
} from "actions/teamsActions";
import { SpinnerContext } from "provider/SpinnerProvider";
import toastr from "toastr";
import { useInstallationDevices } from "hooks/hooks";
import { useMsal } from "@azure/msal-react";

const BorderedGrid = styled(Grid)({
  border: "1px dashed rgb(0, 0, 0, .1)",
  backgroundClip: "padding-box"
});

const TeamContainerPaper = styled(Paper)({
  padding: "10px",
  margin: "15px 0 15px 0",
  minHeight: "100px"
});

const defaultCreateTeam = {
  accountId: "",
  teamName: "",
  handleAllAlerts: true,
  teamType: 1,
  administratorIds: []
};

const resetAlertCodesIfPreviouslyExisting = (
  previous: { deviceId: string; alertCodes: string[] }[] = [],
  current: { deviceId: string; alertCodes: string[] }[] = []
): {
  deviceId: string;
  alertCodes: string[];
}[] => {
  const val: {
    deviceId: string;
    alertCodes: string[];
  }[] = [];

  current.forEach((x) => {
    const item = previous.find((i) => i.deviceId === x.deviceId);
    if (item) {
      val.push({ deviceId: x.deviceId, alertCodes: item.alertCodes });
    } else {
      val.push({ deviceId: x.deviceId, alertCodes: x.alertCodes });
    }
  });
  return val;
};

const filterInvalidIds = (ids: string[] = []) => {
  return ids.reduce((items: string[], currentItem: string) => {
    if (currentItem) {
      return [currentItem, ...items];
    }
    return items;
  }, []);
};

const TeamsPanel = ({
  teams,
  account,
  onTeamsModified
}: {
  teams: Team[];
  account?: Account;
  onTeamsModified: () => void;
}) => {
  const { instance } = useMsal();
  const [teamsContainer, setTeamsContainer] = useState<TeamsContainer>(
    new TeamsContainer()
  );
  const [showRemoveTeamModal, setShowRemoveTeamModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [selectedTeamMember, setSelectedTeamMember] = useState<User>();
  const [showModal, setShowModal] = useState(false);
  const [showRemoveTeamMemberModal, setShowRemoveTeamMemberModal] =
    useState(false);
  const [newTeam, setNewTeam] = useState<{
    [k: string]: CreateTeamDetailsUnionType;
  }>(defaultCreateTeam);
  const [selectedInstallationDevices, setSelectedInstallationDevices] =
    useState<InstallationDevice[]>();
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);

  const installationDevices = useInstallationDevices(account?.accountId);

  useEffect(() => {
    setTeamsContainer(new TeamsContainer(teams));
  }, [teams]);

  const getHashAndOrTwinIdsFromSelectedInstallations = () => {
    let ids: string[] = [];

    selectedInstallationDevices?.forEach((item) => {
      ids = [
        ...ids,
        ...item.devices.map((x) => x.deviceComputedHash),
        ...item.devices.map((x) => x.deviceTwinId)
      ];
    });

    return ids;
  };

  const formatSelectedInstallationsToHashAndOrTwinIdsList = () => {
    let ids: string[] = getHashAndOrTwinIdsFromSelectedInstallations();

    return filterInvalidIds(ids);
  };

  const saveUpdateTeam = () => {
    if (selectedTeam) {
      changeTeam(
        {
          teamId: selectedTeam.teamId,
          teamName: newTeam.teamName,
          teamDescription: newTeam.teamDescription,
          teamType: newTeam.teamType,
          handleAllAlerts: newTeam.handleAllAlerts,
          administratorIds: newTeam.administratorIds
        },
        instance
      )
        .then((teamId) => {
          const previousAlertCodes = newTeam.deviceAlerts as {
            deviceId: string;
            alertCodes: string[];
          }[];

          // TODO JEG: see note at the createTeam below
          const ids = formatSelectedInstallationsToHashAndOrTwinIdsList();
          let deviceAlertCodes = ids.map((x) => {
            return { deviceId: x, alertCodes: ["190"] };
          });

          // Reset alert codes since there is a possibility
          // that the alert codes were modified via API
          deviceAlertCodes = resetAlertCodesIfPreviouslyExisting(
            previousAlertCodes,
            deviceAlertCodes
          );

          return {
            deviceAlertCodes: deviceAlertCodes,
            teamId: teamId as string
          };
        })
        .then(
          (res: {
            deviceAlertCodes: { deviceId: string; alertCodes: string[] }[];
            teamId: string;
          }) =>
            changeDeviceAlertCodes(
              {
                teamId: res.teamId,
                deviceAlertCodes: res.deviceAlertCodes
              },
              instance
            )
        )
        .then(() => {
          onTeamsModified();
        })
        .catch(() => {
          setIsSpinnerVisible(false);
        });
    }
  };

  const saveNewTeam = () => {
    if (teamsContainer.hasNoDuplicateNames()) {
      createTeam(newTeam, instance)
        .then((teamId: string) => {
          // TODO JEG:
          // Update alertCodes when it is implemented.
          // For now, set to "190" because it is a required property.
          // This is temporarily ignored since all teams should handle all alerts.
          const ids = formatSelectedInstallationsToHashAndOrTwinIdsList();
          let deviceAlertCodes = ids.map((x) => {
            return { deviceId: x, alertCodes: ["190"] };
          });

          return {
            deviceAlertCodes: deviceAlertCodes,
            teamId: teamId
          };
        })
        .then(
          async (res: {
            deviceAlertCodes: { deviceId: string; alertCodes: string[] }[];
            teamId: string;
          }) => {
            return await changeDeviceAlertCodes(
              {
                teamId: res.teamId,
                deviceAlertCodes: res.deviceAlertCodes
              },
              instance
            );
          }
        )
        .then(() => {
          onTeamsModified();
        })
        .catch(() => {
          setIsSpinnerVisible(false);
        });
    } else {
      setIsSpinnerVisible(false);
      toastr["warning"]("Team name already exists");
    }
  };

  const saveTeam = () => {
    setIsSpinnerVisible(true);

    if (selectedTeam) {
      saveUpdateTeam();
    } else {
      saveNewTeam();
    }
  };

  const deleteTeam = (team: Team) => {
    setIsSpinnerVisible(true);
    removeTeam(team.teamId, instance)
      .then(() => {
        onTeamsModified();
      })
      .catch(() => setIsSpinnerVisible(false));
  };

  const removeTeamMember = () => {
    setIsSpinnerVisible(true);

    const team = teamsContainer.getTeam(selectedTeamMember?.teamId);

    if (team) {
      const userId = selectedTeamMember?.userId || "";
      const updateUsersList = team.users.filter((u) => u.userId !== userId);

      const updatedTeam: Team = { ...team, users: updateUsersList };

      changeTeamMember(updatedTeam, instance).then(() => {
        onTeamsModified();
      });
    }
  };

  const closeCreateEditModalAndClearSelectedTeam = () => {
    setSelectedTeam(undefined);
    setShowModal(false);
  };

  const closeRemoveMemberModalAndClearSelectedTeam = () => {
    setSelectedTeam(undefined);
    setShowRemoveTeamModal(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <h4>Teams</h4>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowModal(true)}
            disabled
          >
            <AddIcon />
            New Team
          </Button>
        </Grid>
      </Grid>

      <Box
        component="div"
        style={{
          height: "600px",
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        <Grid container spacing={3} data-testid="data-teamslist">
          {teamsContainer.length() > 0
            ? teamsContainer.teams.map((t: Team, index) => (
                <Grid key={index} item xs={12}>
                  <TeamContainerPaper>
                    <TeamContainerHeader
                      title={t.teamName}
                      escalation={t.escalation}
                      accountName={account?.accountName}
                      alertCode={t.alertCode}
                      onSelectOption={(value: string) => {
                        setSelectedTeam(t);
                        if (value === "delete") {
                          setShowRemoveTeamModal(true);
                        } else {
                          setShowModal(true);
                        }
                      }}
                    />

                    <Droppable droppableId={t.teamId} direction="horizontal">
                      {(provided, snapshot) => (
                        <BorderedGrid
                          container
                          spacing={2}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {t.users.length > 0
                            ? t.users.map((u, index) => (
                                <Grid item xs={4} key={u.draggableId}>
                                  <DraggableUser
                                    user={u}
                                    index={index}
                                    isDragDisabled={true}
                                    showClearButton={false}
                                    onClear={() => {
                                      setSelectedTeamMember(u);
                                      setShowRemoveTeamMemberModal(true);
                                    }}
                                  />
                                </Grid>
                              ))
                            : null}
                          {provided.placeholder}
                          <hr />
                        </BorderedGrid>
                      )}
                    </Droppable>
                  </TeamContainerPaper>
                </Grid>
              ))
            : null}
        </Grid>
      </Box>

      <DialogModal
        showCancelButton
        title={"Remove Member"}
        content={
          <div>
            Are you sure you want to remove this member from the team?{" "}
            <b>{`${selectedTeamMember?.primaryEmail}`}</b>
          </div>
        }
        confirmButtonLabel="Yes"
        showModal={showRemoveTeamMemberModal}
        onOk={() => {
          removeTeamMember();
          setShowRemoveTeamMemberModal(false);
        }}
        onHide={() => {
          setShowRemoveTeamMemberModal(false);
        }}
      />

      <DialogModal
        showCancelButton
        showOkButton={false}
        title={selectedTeam ? "Edit Team" : "Create Team"}
        content={
          <CreateUpdateTeam
            account={account}
            installations={installationDevices}
            onChangeData={(
              obj: { [k: string]: CreateTeamDetailsUnionType },
              installationDevices: InstallationDevice[]
            ) => {
              setNewTeam(obj);
              setSelectedInstallationDevices(installationDevices);
            }}
            team={selectedTeam}
          />
        }
        
        showModal={showModal}
        onOk={() => {
          if (newTeam.teamName !== "") {
            saveTeam();
            closeCreateEditModalAndClearSelectedTeam();
          }
        }}
        onHide={() => {
          setNewTeam(defaultCreateTeam);
          closeCreateEditModalAndClearSelectedTeam();
        }}
      />
      <DialogModal
        showCancelButton
        title="Remove Team"
        content={
          <div>
            Are you sure you want to remove this team:{" "}
            <b>{`${selectedTeam?.teamName}`}</b>?
          </div>
        }
        confirmButtonLabel="Yes"
        showModal={showRemoveTeamModal}
        onOk={() => {
          if (selectedTeam) {
            deleteTeam(selectedTeam);
            closeRemoveMemberModalAndClearSelectedTeam();
          }
        }}
        onHide={() => {
          closeRemoveMemberModalAndClearSelectedTeam();
        }}
      />
    </div>
  );
};

export default TeamsPanel;
