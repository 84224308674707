import React from "react";
import { SpinnerProvider } from "provider/SpinnerProvider";
import Spinner from "../DashboardLayout/Spinner";

const GenericLayout = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  return (
    <SpinnerProvider>
      {children}
      <Spinner />
    </SpinnerProvider>
  );
};

export default GenericLayout;
