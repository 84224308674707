import CSS from "csstype";
import { CSSProperties } from "react";

export const mainStyle: CSSProperties = {
  position: "absolute",
  width: "100%",
  top: "100px",
  bottom: 0,
  backgroundImage:
    "url(http://www.grundfos.com/content/dam/Global%20Site/globla-menu-items/Grundfos_Logo-Symbol_Blue.png)",
  backgroundSize: "35% auto",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "right",
  backgroundPositionY: "bottom"
};

export const sectionStyle: CSSProperties = {
  padding: "20px 53px",
  maxWidth: "60%",
  position: "relative",
  top: "40%",
  transform: "translateY(-50%)"
};

export const baseStyle: CSSProperties = {
  color: "#004978",
  margin: 0,
  fontFamily: "Lucida Grande,Tahoma,Geneva,Verdana,sans-serif"
};

export const h1Style: CSSProperties = {
  fontSize: "60px",
  marginBottom: "10px"
};

export const h3Style: CSSProperties = {
  fontSize: "35px",
  marginBottom: "30px"
};

export const pStyle: CSSProperties = {
  fontSize: "29px"
};

export const aStyle: CSSProperties = {
  fontSize: "29px",
  color: "#00aeef",
  textDecoration: "none"
};
