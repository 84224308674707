import CSS from "csstype";

export const mainStyle: CSS.Properties = {
  position: "absolute",
  width: "100%",
  top: "0",
  bottom: 0,
  backgroundColor: "#666666",
  backgroundSize: "50% auto",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "right",
  backgroundPositionY: "bottom",
};
