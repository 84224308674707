import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { IPageHeaderProps } from "./type";
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginTop: "10px",
    display: "flex",
    paddingBottom: theme.spacing(3),
    marginLeft: "10px",
  },
  leftPanelHeader: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: "GrundfosTheSans, Arial, sans-serif",
    letterSpacing: "2px",
    margin: 0,
    marginLeft: "-2px",
    marginTop: "10px",
  },
  subTitle: {
    color: theme.palette.primary.light,
    fontSize: "12px",
    textTransform: "uppercase",
    textDecoration: "underline",
    margin: 0,
  },
}));

const PageHeader = ({
  title,
  subTitle,
  details,
  icon,
}: IPageHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      {icon}
      <Box className={classes.leftPanelHeader}>
        {subTitle && <h6 className={classes.subTitle}>{subTitle}</h6>}
        <h2 className={classes.title}>{title}</h2>
        {details && <Box>{details}</Box>}
      </Box>
    </Box>
  );
};
export default PageHeader;
