import { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import {
  getAccountNames,
  getInstallationDevices,
  getTeamDetails,
  getTeamsByAccountId,
  getUsersByAccountId,
} from "../actions/teamsActions";
import { Team, User } from "../pages/Teams/AssignUsersToTeams/types";
import { generateUUID, isNullOrEmpty } from "utils/utils";
import { InstallationDevice } from "pages/Teams/AssignUsersToTeams/TeamsPanel/types";

export function useAccount(
  accId: string = "",
  refresh = false,
  cb: () => void | undefined
) {
  const { instance } = useMsal();
  const [accountNames, setAccountNames] =
    useState<{ accountId: string; accountName: string }>();

  useEffect(() => {
    if (!isNullOrEmpty(accId)) {
      getAccountNames([accId], instance).then(
        (accountsInfo: { value: string; label: string; rootId: string }[]) => {
          if (cb) {
            cb();
          }
          setAccountNames({
            accountId: accId,
            accountName:
              accountsInfo[0]?.label ||
              "Account name could not be retrieved from asset service",
          });
        }
      );
    }
  }, [accId, refresh]);

  return accountNames;
}

export function useAccountUsers(
  accId: string = "",
  refresh = false,
  cb: () => void | undefined
) {
  const { instance } = useMsal();
  const [accountUsers, setAccountUsers] = useState<User[]>();

  useEffect(() => {
    if (!isNullOrEmpty(accId)) {
      getUsersByAccountId(accId, instance).then((users: User[]) => {
        if (cb) {
          cb();
        }
        setAccountUsers(users);
      });
    }
  }, [accId, refresh]);

  return accountUsers;
}

export function useTeamsByAccountId(
  accId: string = "",
  refresh = false,
  cb: () => void | undefined
) {
  const { instance } = useMsal();
  const [teams, setTeams] = useState<Team[]>();

  useEffect(() => {
    if (!isNullOrEmpty(accId)) {
      getTeamsByAccountId(accId, instance).then((teams: Team[]) => {
        const teamsCopy = teams;
        teamsCopy.forEach((t) => {
          t.users.forEach((u) => {
            u.draggableId = generateUUID();
          });
        });
        if (cb) {
          cb();
        }
        setTeams(teamsCopy);
      });
    }
  }, [accId, refresh]);

  return teams;
}

export function useInstallationDevices(accId: string = "") {
  const { instance } = useMsal();
  const [installationDevices, setInstallationDevices] =
    useState<InstallationDevice[]>();

  useEffect(() => {
    if (!isNullOrEmpty(accId)) {
      getInstallationDevices(accId, instance).then(
        (insDev: InstallationDevice[]) => {
          setInstallationDevices(insDev);
        }
      );
    }
  }, [accId]);

  return installationDevices;
}

export function useTeamDetails(teamId: string = "") {
  const { instance } = useMsal();
  const [teamDetails, setTeamDetails] = useState<Team>();

  useEffect(() => {
    if (!isNullOrEmpty(teamId)) {
      getTeamDetails(teamId, instance).then((teamDetailsResult: Team) => {
        setTeamDetails(teamDetailsResult);
      });
    }
  }, [teamId]);

  return teamDetails;
}
