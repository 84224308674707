import React, { ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { RouteConfig } from "react-router-config";
import { PrivateRoute } from "../components";
import App from "./App/App";
import Logs, { LogsList } from "pages/Logs";
import Codes, { CodeList } from "pages/CodeManagement";
import Teams, { TeamList, AssignUsersToTeams } from "pages/Teams";
import Authorizations, { AuthorizationList } from "pages/Authorization";
import Acknowledge, { GenericPage } from "pages/Acknowledge";
import {
  LogsPath,
  CodeManagementPath,
  TeamsPath,
  AuthorizationPath,
  AcknowledgePath,
} from "./paths";

const privateRoute = (props: any) => (
  <PrivateRoute component={App} props={props} />
);
privateRoute.displayName = "PrivateRoute";

const redirect = (to = LogsPath, from = "/"): JSX.Element => {
  return <Redirect from={from} to={to} />;
};
redirect.displayName = "Redirect";

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const NoMatch404 = function NoMatch(): JSX.Element {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

export default [
  {
    path: "/",
    render: (props): ReactNode => privateRoute(props),
    routes: [
      {
        exact: true,
        path: "/",
        render: (): ReactNode => redirect(TeamsPath, "/"),
      },
      {
        component: Logs,
        path: LogsPath,
        routes: [
          {
            component: LogsList,
            exact: true,
            path: LogsPath,
          },
          { component: NoMatch404, path: "*" },
        ],
      },
      {
        component: Codes,
        path: CodeManagementPath,
        routes: [
          {
            component: CodeList,
            exact: true,
            path: CodeManagementPath,
          },
        ],
      },
      {
        component: Teams,
        path: TeamsPath,
        routes: [
          {
            component: TeamList,
            exact: true,
            path: TeamsPath,
          },
          {
            component: AssignUsersToTeams,
            exact: true,
            path: "/teams/:accountId/assignUsersToTeams",
          },
        ],
      },
      {
        component: Authorizations,
        path: AuthorizationPath,
        routes: [
          {
            component: AuthorizationList,
            exact: true,
            path: AuthorizationPath,
          },
        ],
      },
      {
        component: Acknowledge,
        path: AcknowledgePath,
        routes: [
          {
            component: GenericPage,
            exact: true,
            path: AcknowledgePath,
          },
        ],
      },
      { component: NoMatch404, path: "*" },
    ],
  },
] as RouteConfig[];
