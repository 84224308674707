import React, { createContext } from "react";

export const LogsContext = createContext<any>([{}, () => {}]);

export const LogsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  
  return (
    <LogsContext.Provider value="">
      {children}
    </LogsContext.Provider>
  );
};