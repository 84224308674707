import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { IDialogModalProps } from "./type";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogModal = ({
  title,
  message,
  showModal,
  onOk,
  onHide,
  content,
  confirmButtonLabel,
  cancelButtonLabel,
  showCancelButton,
  showOkButton = true,
  formId,
  icon,
}: IDialogModalProps) => {
  return (
    <div>
      <Dialog
        open={showModal}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title" onClose={onHide}>
          {icon} {title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          {content}
        </DialogContent>
        {showCancelButton && <Divider />}
        <DialogActions>
          {showCancelButton && (
            <Button onClick={onHide}>
              {cancelButtonLabel ? cancelButtonLabel : "Cancel"}
            </Button>
          )}
          {showOkButton && (
            <Button
              disabled={!showModal}
              variant="contained"
              form={formId}
              type={formId ? "submit" : "button"}
              onClick={() => {
                onOk();
              }}
              color="primary"
              autoFocus
            >
              {confirmButtonLabel ? confirmButtonLabel : "OK"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogModal;
