import React, { useState, createContext } from "react";

export const GenericContext = createContext<any>([{}, () => {}]);

export const GenericProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [loadComments, setLoadComments] = useState({});

  return (
    <GenericContext.Provider
      value={{
        loadCommentsContext: [loadComments, setLoadComments],
      }}
    >
      {children}
    </GenericContext.Provider>
  );
};
