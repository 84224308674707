import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { ISubContainerProps } from "./type";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginTop: "10px",
    display: "flex",
    paddingBottom: theme.spacing(3),
    marginLeft: "30px",
  },
  leftPanelHeader: {
    flexGrow: 1,
    display: "flex",
  },
  title: {
    color: "#f2f2f2",
    fontFamily: "GrundfosTheSans, Arial, sans-serif",
    letterSpacing: "2px",
    margin: 0,
    marginLeft: "-2px",
  },
  subTitle: {
    color: theme.palette.primary.light,
    fontSize: "8px",
    textTransform: "uppercase",
    textDecoration: "underline",
    margin: 0,
  },
}));

const SubContainer = ({
  children,
  details,
}: ISubContainerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.leftPanelHeader}> {children} </Box>
      {details && <Box>{details}</Box>}
    </Box>
  );
};
export default SubContainer;
