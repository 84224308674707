import React from "react";
import Layout from "layouts";
import { renderRoutes } from "react-router-config";
import { IElementRouteAttribute } from "routes/type";
import { AuthorizationProvider } from "provider/AuthorizationProvider";

const Logs = ({ route }: IElementRouteAttribute) => {
  return (
    <AuthorizationProvider>
      <Layout>
        {renderRoutes(route.routes)}
      </Layout>
    </AuthorizationProvider>
  );
};

export default Logs;
