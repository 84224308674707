import React from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

export const AuthProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  // For additional MsalAuthenticationTemplate parameteres see:
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {children}
    </MsalAuthenticationTemplate>
  );
};
