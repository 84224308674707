import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import styled from "styled-components";

import { IMainTabsProps } from "./type";

const StyledLink = styled(Link)`
  font-weight: bold !important;
  &:hover {
    text-decoration: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const MainTabs = ({ tabs }: IMainTabsProps): JSX.Element => {
  const location = useLocation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: number): void => {
    setSelectedTab(value);
  };

  useEffect(() => {
    const locations = tabs.map((tab) => tab.path);

    setSelectedTab(
      locations.indexOf(location.pathname) < 0
        ? 0
        : locations.indexOf(location.pathname)
    );
  }, [tabs]);

  return (
    <Tabs
      value={selectedTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="tabs"
      className={classes.root}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={`tabKey${index}`}
          label={tab.label}
          component={StyledLink}
          to={tab.path}
        />
      ))}
    </Tabs>
  );
};

export default MainTabs;
