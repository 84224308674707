import React, { createContext } from "react";

export const AuthorizationContext = createContext<any>([{}, () => {}]);

export const AuthorizationProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  
  return (
    <AuthorizationContext.Provider value="">
      {children}
    </AuthorizationContext.Provider>
  );
};