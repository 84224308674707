import React from "react";
import { styled } from "@material-ui/core/styles";
import { Draggable } from "react-beautiful-dnd";
import { Paper, Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import IconButton from "@material-ui/core/IconButton";
import { User } from "./types";

import Tooltip from "@material-ui/core/Tooltip";

type DraggableUserProps = {
  user: User;
  index: number;
  showClearButton?: boolean;
  isDragDisabled?: boolean;
  onClear?: () => void;
};

const DraggablePaper = styled(Paper)({
  padding: "10px",
  margin: "5px 0 5px 0",
});

const EllipsisedGrid = styled(Grid)({
  textAlign: "left",
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const DraggableUser = ({
  user,
  index,
  showClearButton = true,
  isDragDisabled = false,
  onClear,
}: DraggableUserProps) => {
  const title = user.primaryEmail || "";

  return (
    <Draggable
      draggableId={user.draggableId || ""}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <DraggablePaper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                {!isDragDisabled && <DragIndicatorIcon opacity="25%" />}
              </Grid>
              <Tooltip title={title}>
                <EllipsisedGrid item xs={8} zeroMinWidth>
                  {title}
                </EllipsisedGrid>
              </Tooltip>
              <Grid item>
                {showClearButton && (
                  <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={() => {
                      if (onClear) {
                        onClear();
                      }
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        </DraggablePaper>
      )}
    </Draggable>
  );
};

export default DraggableUser;
