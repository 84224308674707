import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullTimeFormats } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";
import { useMsal } from "@azure/msal-react";

export const TimeFormatsContext = createContext<any>(null);

export const TimeFormatsProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const { instance } = useMsal();
  const [timeFormats, setTimeFormats] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getTimeFormats = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(timeFormats) || dateTimeRefresh < dateTime) {
      await pullTimeFormats(instance).then((timeFormats) => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setTimeFormats(timeFormats);
      });
    }
  };

  return (
    <TimeFormatsContext.Provider value={[timeFormats, getTimeFormats]}>
      {children}
    </TimeFormatsContext.Provider>
  );
};
