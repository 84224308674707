import React from "react";

import {
  mainStyle,
  sectionStyle,
  baseStyle,
  h1Style,
  h3Style,
  pStyle,
  aStyle
} from "./styles";
const Denied = () => (
  <div>
    <head>
      <title>404 - Access Denied | Grundfos</title>
    </head>

    <body>
      <header>
        <img
          src="https://www.grundfos.com/etc/designs/grundfos/internet/responsive/images/grundfoslogo.png"
          title="Grundfos logo"
          alt="Grundfos logo"
        />
      </header>

      <main style={mainStyle}>
        <section style={sectionStyle}>
          <h1 style={{ ...baseStyle, ...h1Style }}>Sorry...</h1>
          <h3 style={{ ...baseStyle, ...h3Style }}>
            It seems like you don&rsquo;t have permission to access this page.
          </h3>
          <p style={{ ...baseStyle, ...pStyle }}>
            Please contact system Administrator.
          </p>
          <a
            href="https://www.grundfos.com/nav/contact.html"
            title="Go to the contact us page..."
            style={{ ...baseStyle, ...aStyle }}
          >
            Contact.
          </a>
        </section>
      </main>
    </body>
  </div>
);
export default Denied;
