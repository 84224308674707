import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { IMainBarDashboardProps } from "./type";

import { useHistory } from "react-router-dom";

import Search from "./Search";
import Breadcrumbs from "./Breadcrumbs";

import MainTabs from "components/MainTabs";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    top: "64px",
    left: 0,
    backgroundColor: grey[200],
    height: "50px",
    display: "flex",
    alignItems: "center",
  },
  filterButtonStyle: {
    float: "right",
    marginLeft: 5,
  },
  filtersPanelStyle: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: "114px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 3px 3px #dadada",
  },
  doubleOffset: {
    marginTop: "100px",
  },
  offset: {
    marginTop: "50px",
  },
  backContainer: {
    position: "relative",
    left: "-20px",
    display: "flex",
    alignItems: "center",
  },
  back: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filteringItemsContainerStyle: {
    width: "100%",
    padding: "10px 0",
  },
}));

const MainBarDashboard = ({
  children,
  filter,
  filterComponents,
  search,
  searchPlaceholder,
  onSearch,
  breadcrumbs,
  back,
  backUrl,
  tabs,
}: IMainBarDashboardProps): JSX.Element => {
  const [isFilterHidden, setIsFilterHidden] = useState(true);

  const history = useHistory();
  const classes = useStyles();
  const onBack = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      <div className={`${classes.root} MuiToolbar-gutters`}>
        {back && (
          <div className={classes.backContainer}>
            <IconButton onClick={onBack}>
              <NavigateBeforeIcon className={classes.back} />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
          </div>
        )}

        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {search && (
          <Search searchPlaceholder={searchPlaceholder} onSearch={onSearch} />
        )}
        {filter && (
          <div className={classes.filterButtonStyle}>
            <Tooltip title="Filters">
              <IconButton
                onClick={() => setIsFilterHidden(!isFilterHidden)}
                color={isFilterHidden ? "secondary" : "primary"}
              >
                <FontAwesomeIcon size="sm" icon={faFilter} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      {filter && (
        <div className={classes.filtersPanelStyle} hidden={isFilterHidden}>
          <Box
            className={classes.filteringItemsContainerStyle}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            bgcolor="background.paper"
          >
            {filterComponents
              ? filterComponents.map((c, index) => (
                  <Fragment key={index}>{c}</Fragment>
                ))
              : null}
          </Box>
        </div>
      )}
      <div className={isFilterHidden ? classes.offset : classes.doubleOffset}>
        {tabs && <MainTabs tabs={tabs} />}
        {children}
      </div>
    </Fragment>
  );
};

export default MainBarDashboard;
