import React from "react";

import { AuthProvider } from "./AuthProvider";
import { DrawerProvider } from "./DrawerProvider";
import { CountriesProvider } from "./CountriesProvider";
import { ApplicationsProvider } from "./ApplicationsProvider";
import { TypesProvider } from "./TypesProvider";
import { DateFormatsProvider } from "./DateFormatsProvider";
import { LanguagesProvider } from "./LanguagesProvider";
import { TimeFormatsProvider } from "./TimeFormatsProvider";
import { TimeZonesProvider } from "./TimeZonesProvider";
import { UnitSystemsProvider } from "./UnitSystemsProvider";
import { IOTSystemsProvider } from "./IOTSystemsProvider";
import { SavedFormsProvider } from "./SavedFormsProvider";

export const RootProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  return (
    <AuthProvider>
      <DrawerProvider>
        <CountriesProvider>
          <ApplicationsProvider>
            <TypesProvider>
              <DateFormatsProvider>
                <LanguagesProvider>
                  <TimeFormatsProvider>
                    <TimeZonesProvider>
                      <IOTSystemsProvider>
                        <UnitSystemsProvider>
                          <SavedFormsProvider>{children}</SavedFormsProvider>
                        </UnitSystemsProvider>
                      </IOTSystemsProvider>
                    </TimeZonesProvider>
                  </TimeFormatsProvider>
                </LanguagesProvider>
              </DateFormatsProvider>
            </TypesProvider>
          </ApplicationsProvider>
        </CountriesProvider>
      </DrawerProvider>
    </AuthProvider>
  );
};
