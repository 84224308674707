import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  TextFieldProps,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Account, CreateTeamDetailsUnionType, Team } from "../../types";
import InstallationItemCheckbox from "./InstallationItemCheckbox";
import { InstallationDevice, InstallationDevice_Device } from "../types";
import styled from "styled-components";
import { styled as styledMUI } from "@material-ui/core/styles";
import { useAccount, useMsal } from "@azure/msal-react";
import { useTeamDetails } from "hooks/hooks";
import { getTokenOid } from "utils/utils";

const InputField = (props: TextFieldProps) => (
  <TextField fullWidth margin="normal" {...props} />
);

const InstallationLabel = styled.label`
  margin: 0;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const InstallationContainer = styledMUI(Paper)({
  height: "150px",
  overflowY: "auto",
  overflowX: "hidden",
});

const isLoggedUserInAdminList = (userOid = "", ids: string[] = []) => {
  const currentAdminIndex = ids.findIndex((x) => x === userOid);

  return currentAdminIndex !== -1;
};

const getAdminIds = (userOid = "", teamDetailsResult: Team): string[] => {
  let adminIds: string[] = [];

  if (teamDetailsResult.administratorId) {
    if (isLoggedUserInAdminList(userOid, teamDetailsResult.administratorId)) {
      adminIds = teamDetailsResult.administratorId;
    } else {
      adminIds = [...teamDetailsResult.administratorId, userOid];
    }
  }

  return adminIds;
};

const getTeamDeviceIds = (t: Team): string[] => {
  let val: string[] = [];

  if (t.deviceAlerts) {
    val = t.deviceAlerts.map((x) => x.deviceId);
  }

  return val;
};

const CreateUpdateTeam = ({
  account,
  onChangeData,
  installations,
  team,
}: {
  account?: Account;
  onChangeData: (
    obj: { [k: string]: CreateTeamDetailsUnionType },
    installationDevices: InstallationDevice[]
  ) => void;
  installations?: InstallationDevice[];
  team: Team | undefined;
}) => {
  const { accounts } = useMsal();
  const adAccount = useAccount(accounts[0] || {});
  const getOid = () => getTokenOid(adAccount?.idTokenClaims) ?? "";
  const [teamDetails, setTeamDetails] = useState<{
    [k: string]: CreateTeamDetailsUnionType;
  }>({
    accountId: account?.accountId ?? "",
    teamName: "",
    teamDescription: "",
    handleAllAlerts: true,
    teamType: 1,
    administratorIds: [getOid()],
  });
  const [checkedInstallations, setCheckedInstallations] = useState<
    InstallationDevice[]
  >([]);
  const [teamInstallationIds, setTeamInstallationIds] = useState<string[]>([]);

  const partialTeamDetails = useTeamDetails(team?.teamId);

  const toListOfHashAndOrTwinIds = (
    devices: InstallationDevice_Device[] = []
  ) => {
    const hashIdsList = devices.map((d) => d.deviceComputedHash);
    const twinIdsList = devices.map((d) => d.deviceTwinId);

    return [...hashIdsList, ...twinIdsList];
  };

  const getInstallationsContainingHashOrTwinIds = (
    teamHashAndOrTwinIds: string[]
  ) => {
    let ins = installations ? installations : [];

    return ins.filter(
      (x: InstallationDevice) =>
        teamHashAndOrTwinIds.findIndex((d) =>
          toListOfHashAndOrTwinIds(x.devices).includes(d)
        ) !== -1
    );
  };

  useEffect(() => {
    if (partialTeamDetails) {
      const teamDetailsResult = partialTeamDetails;
      const adminIds: string[] = getAdminIds(getOid(), teamDetailsResult);

      const teamHashAndOrTwinIds = getTeamDeviceIds(teamDetailsResult);

      const teamInstallations =
        getInstallationsContainingHashOrTwinIds(teamHashAndOrTwinIds);

      setTeamInstallationIds(teamInstallations.map((i) => i.installationId));
      setCheckedInstallations(teamInstallations);

      setTeamDetails({
        accountId: teamDetailsResult.accountId,
        teamName: teamDetailsResult.teamName || "",
        handleAllAlerts: teamDetailsResult.handleAllAlerts ? true : false,
        teamType:
          teamDetailsResult.teamType?.toLowerCase() === "broadcast" ? 1 : 2,
        administratorIds: adminIds,
        deviceAlerts: teamDetailsResult.deviceAlerts
          ? teamDetailsResult.deviceAlerts
          : [],
        teamDescription: teamDetailsResult.teamDescription || "",
      });
    }
  }, [team, partialTeamDetails]);

  const triggerChangeEvent = (
    obj: {
      [k: string]: CreateTeamDetailsUnionType;
    },
    installationDevices: InstallationDevice[]
  ) => {
    onChangeData({ ...obj }, [...installationDevices]);
    setTeamDetails({ ...obj });
  };

  const handleChange = (value: string | boolean | number, prop: string) => {
    const newObj: { [k: string]: CreateTeamDetailsUnionType } = {
      ...teamDetails,
    };

    newObj[prop] = value;

    triggerChangeEvent(newObj, checkedInstallations);
  };

  const addToCheckedInstallations = (id = "") => {
    const newObj: { [k: string]: CreateTeamDetailsUnionType } = {
      ...teamDetails,
    };
    let ins = installations ? installations : [];
    const item = ins.find((x) => x.installationId === id);

    if (item) {
      const ins = [item, ...checkedInstallations];
      setCheckedInstallations(ins);
      triggerChangeEvent(newObj, ins);
    }
  };

  const removeFromCheckedInstallations = (id = "") => {
    const newObj: { [k: string]: CreateTeamDetailsUnionType } = {
      ...teamDetails,
    };

    const ins = checkedInstallations.filter((x) => x.installationId !== id);
    setCheckedInstallations(ins);
    triggerChangeEvent(newObj, ins);
  };

  const onInstallationsChange = (checked = false, id = "") => {
    if (checked) {
      addToCheckedInstallations(id);
    } else {
      removeFromCheckedInstallations(id);
    }
  };

  const isInstallationChecked = (installationId = ""): boolean => {
    const index = teamInstallationIds.findIndex((x) => x === installationId);

    return index !== -1;
  };

  /**
   * TODO: Temporarily unused because handle all alerts is currently disabled.
   * Bind this method to the "Handle All Alerts" checkbox below once
   * it has been implemented.
   */
  const shouldHandleAllAlerts = () => {
    return teamDetails.handleAllAlerts ? true : false;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <InputField
          data-testid="data-accountName"
          label="Account"
          defaultValue={account?.accountName}
          required
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          error={teamDetails.teamName === ""}
          label="Team Name"
          value={teamDetails.teamName}
          onChange={(e) => handleChange(e.target.value, "teamName")}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          label="Description"
          multiline
          rowsMax={5}
          value={teamDetails.teamDescription}
          onChange={(e) => handleChange(e.target.value, "teamDescription")}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Team Type</InputLabel>
          <Select
            disabled
            value={teamDetails.teamType}
            onChange={(e) => {
              handleChange(e.target.value as number, "teamType");
            }}
          >
            <MenuItem value={1}>Broadcast</MenuItem>
            <MenuItem value={2}>Escalation</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <InstallationLabel>Installations</InstallationLabel>
        <InstallationContainer variant="outlined">
          <Grid container spacing={1}>
            {installations && installations.length > 0
              ? installations.map((installation: InstallationDevice, index) => (
                  <Grid key={index} item xs={6}>
                    <InstallationItemCheckbox
                      installation={installation}
                      handleChange={onInstallationsChange}
                      checked={isInstallationChecked(
                        installation.installationId
                      )}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        </InstallationContainer>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          style={{ margin: "0" }}
          value="handleAllAlerts"
          control={
            <Checkbox
              disabled
              color="primary"
              checked={true}
              onChange={(e) =>
                handleChange(e.target.checked, "handleAllAlerts")
              }
            />
          }
          label="Handle All Alerts"
          labelPlacement="start"
        />
      </Grid>
    </Grid>
  );
};

export default CreateUpdateTeam;
