import React, { Fragment, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

import { IEnhancedPaginatedTableProps, IHeadCell } from "./type";

const EnhancedPaginatedTable = ({
  headCells,
  tableLabel,
  buttonGroup,
  getData,
  refresh,
  query,
}: IEnhancedPaginatedTableProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
      headerLabel: {
        fontWeight: "bold",
      },
      button: {
        margin: "auto !important",
      },
      skeleton: {
        margin: theme.spacing(2),
        width: "100%",
      },
      link: {
        textAlign: "left",
      },
    })
  );

  type Order = "asc" | "desc";

  interface EnhancedPaginatedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: IHeadCell[];
  }

  const EnhancedPaginatedTableHead = (
    props: EnhancedPaginatedTableHeadProps
  ) => {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property: string) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells
            .filter((headcell) => !headcell.hidden)
            .map((headCell, index) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.headerLabel}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
      },
      highlight:
        theme.palette.type === "light"
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
      title: {
        flex: "1 1 100%",
        padding: "5px 10px 0",
        color: theme.palette.primary.main,
        fontFamily: "GrundfosTheSans,Arial,sans-serif",
        fontSize: "25px",
      },
    })
  );

  const EnhancedPaginatedTableToolbar = () => {
    const classesToolbar = useToolbarStyles();

    return (
      <Typography className={classesToolbar.title} variant="h6" id="tableTitle">
        {tableLabel}
      </Typography>
    );
  };

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("digitalOfferingId");
  const [data, setData] = React.useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    setIsLoading(true);
    getData(page, rowsPerPage, query).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [getData, page, rowsPerPage, refresh]);

  useEffect(() => {
    setIsLoading(true);
    setPage(1);
    getData(1, rowsPerPage, query).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [getData, query]);

  const getTotalRows = (d: any) => {
    let trows = 0;

    trows = d ? d.totalRows : 0;

    return trows;
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, getTotalRows(data) - (page - 1) * rowsPerPage);

  const renderSkeletonRow = () => {
    return [...Array(rowsPerPage)].map((num: number, index: number) => (
      <TableRow key={`skeletongKey${index}`}>
        {headCells
          .filter((headcell) => !headcell.hidden)
          .map((cell, key) => {
            return (
              <TableCell key={key}>
                <Skeleton />
              </TableCell>
            );
          })}
      </TableRow>
    ));
  };

  const clickLink = (cell: any, refOnClickParam = {}) => {
    cell.onClick && cell.referenceIds && cell.onClick(refOnClickParam);
  };

  const bGroup = buttonGroup;

  const renderCell = (row: any, cell: IHeadCell, refOnClickParam: any) => {
    if (row && cell) {
      if (cell.isClickable && !cell.isIconStyle) {
        if (cell.isArray && cell.arrayReference && cell.arrayReferenceField) {
          return (
            <Fragment>
              {row[cell.arrayReference].map((arr: any, index: number) => {
                return (
                  <div key={`arrayClickable${index}`}>
                    {index !== 0 && ", "}
                    <Link
                      component="button"
                      onClick={() => {
                        refOnClickParam.fieldValue =
                          arr[cell.arrayReferenceField!];
                        clickLink(cell, refOnClickParam);
                      }}
                      className={classes.link}
                    >
                      {arr[cell.arrayReferenceField!]}
                    </Link>
                  </div>
                );
              })}
            </Fragment>
          );
        } else {
          return (
            <Link
              component="button"
              onClick={() => {
                clickLink(cell, refOnClickParam);
              }}
              className={classes.link}
            >
              {row[cell.fieldName!]}
            </Link>
          );
        }
      } else if (
        cell.isArray &&
        cell.arrayReference &&
        cell.arrayReferenceField
      ) {
        return (
          <Fragment>
            {row[cell.arrayReference]
              .map((arr: any) => arr[cell.arrayReferenceField!])
              .join(", ")}
          </Fragment>
        );
      } else if (cell.isMultipleFields && cell.referenceFields) {
        return (
          <Fragment>
            {cell.referenceFields!.map((arg: any) => row[arg]).join(", ")}
          </Fragment>
        );
      } else if (cell.isList && cell.arrayReferenceField) {
        return (
          <Fragment>
            {row[cell.arrayReferenceField].map((arg: any) => arg).join(", ")}
          </Fragment>
        );
      } else if (cell.hasAction && cell.actions) {
        return (
          <ButtonGroup size="small" aria-label="small outlined button group">
            {cell.actions!.map((action: any, key: number) => {
              const refOnClickParamActions = {};
              if (action.referenceIds) {
                action.referenceIds.map((refId: string) => {
                  (refOnClickParamActions as any)[refId] = row[refId];
                });
              }
              return (
                <Button
                  disabled={action.referenceIds.length === 3 ? true : false}
                  key={key}
                  onClick={() => action.onSubmit(refOnClickParamActions)}
                  classes={{
                    startIcon: classes.button,
                  }}
                  startIcon={<action.icon />}
                >
                  {""}
                </Button>
              );
            })}
          </ButtonGroup>
        );
      }
      return (
        <Fragment>
          {row[cell.fieldName!]}
          {cell.isClickable && cell.isIconStyle && (
            <IconButton
              aria-label="info"
              size="small"
              onClick={(): void => {
                clickLink(cell, refOnClickParam);
              }}
            >
              <InfoIcon />
            </IconButton>
          )}
        </Fragment>
      );
    }
  };

  const getDataArray = (d: any): any[] => {
    let dataArray = [];

    if(d){
      dataArray = d.data ? d.data : [];
    }

    return dataArray;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {bGroup && buttonGroup}
        {tableLabel && <EnhancedPaginatedTableToolbar />}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedPaginatedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={getTotalRows(data)}
              headCells={headCells}
            />
            <TableBody>
              {isLoading
                ? renderSkeletonRow()
                : !isEmpty(data)
                ? getDataArray(data).map((row: any, index: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {headCells
                          .filter((headcell) => !headcell.hidden)
                          .map((cell, key) => {
                            const refOnClickParam = {};
                            if (cell.referenceIds) {
                              cell.referenceIds.map((refId: string) => {
                                return ((refOnClickParam as any)[refId] =
                                  row[refId]);
                              });
                            }
                            return (
                              <TableCell key={key}>
                                {renderCell(row, cell, refOnClickParam)}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })
                : renderSkeletonRow()}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell
                    colSpan={
                      headCells.filter((headcell) => !headcell.hidden).length
                    }
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={getTotalRows(data)}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedPaginatedTable;
