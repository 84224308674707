import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import {
  IAcknowledgeBody,
  IAlertLogParams,
  IAcknowledgeResults,
  IPagination,
} from "components/type";
import toastr from "toastr";
import { IPublicClientApplication } from "@azure/msal-browser";

const header = {
  "content-type": "application/json",
};

const errorHeaderText = "Error occured while processing your request!";

export const AcknowledgeAlert = (
  data: IAcknowledgeBody,
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.AcknowledgeAlert}`,
    {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    },
    instance,
    true
  )
    .then((res) => {
      if (!res.ok) {
        toastr["error"](errorHeaderText, "Error Saving");
      } else {
        toastr["success"]("Successfully Added!");
      }
      return res;
    })
    .then((response) => response.json())
    .catch((e) => {
      console.log(e);
    });
};

export const getAlertLogs = async (
  param: IAlertLogParams,
  instance: IPublicClientApplication
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.AlertLogs}/${param.alertCorrelationId}/${param.accountId}`,
    {
      method: "GET",
      headers: header,
    },
    instance,
    true
  ).then((res) => {
    return res.json();
  });
};

// TODO: Pagination
export const getAlertsLogsTable = async (
  // page? = 1,
  // limit? = 10,
  query: any,
  instance: IPublicClientApplication
): Promise<IPagination[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.AlertLogs}/${query}`,
    {
      method: "GET",
      headers: header,
    },
    instance,
    true
  )
    .then((response) => response.json())
    .then((alerts: any) => {
      const ackData: IAcknowledgeResults[] = [];
      let paginationResult: IPagination[] = [];
      if (alerts) {
        alerts.acknowledgements.forEach((element: any) => {
          const ack = {
            alertAcknowledgedBy: element.alertAcknowledgedBy.value,
            alertAcknowledgeWhen: element.alertAcknowledgeWhen.value,
            alertAcknowledgeFeedback: element.alertAcknowledgeFeedback.value,
          };
          ackData.push(ack);
        });
        paginationResult = [
          {
            data: ackData,
            nextPage: "2",
            previousPage: "1",
            totalRows: 10,
            totalPages: 5,
          },
        ];
      }
      return paginationResult;
    });
};
