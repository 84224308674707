import { IPublicClientApplication } from "@azure/msal-browser";
import { adalApiFetch } from "../../clientApp/configAdal";
import { endPoints } from "../../clientApp/endPoints";

export const pullIOTSystems = async (instance: IPublicClientApplication) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.IOTSystems}/GetAll`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    },
    instance
  )
    .then((res) => res.json())
    .then((posts) => {
      return posts.iotSystems;
    });
};
