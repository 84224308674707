import React, { Fragment, useState } from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaperHeading from "components/PaperHeading";

import { IExpansionPanelProps } from "./type";

const MUIExpansionPanel = ({
  children,
  label,
  defaultExpanded,
  expansionActionButtons,
  hasCheckbox,
  isChecked,
  onChangeChecked,
}: IExpansionPanelProps): JSX.Element => {
  const [, setcBcheck] = useState<boolean>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      expansionPanel: {
        flexDirection: "column",
      },
      expansionPanelSummary: {
        flexDirection: "row-reverse",
      },
    })
  );
  const classes = useStyles();
  const handleClick = (cb: any) => {
    setcBcheck(cb.target.checked);
    if (onChangeChecked) {
      onChangeChecked(cb.target.checked);
    }
  };
  return (
    <ExpansionPanel
      defaultExpanded={defaultExpanded != null ? defaultExpanded : true}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.expansionPanelSummary}
      >
        <label>
          {" "}
          {hasCheckbox ? (
            <input
              type="checkbox"
              defaultChecked={isChecked}
              onChange={(e: any) => handleClick(e)}
            />
          ) : (
            ""
          )}{" "}
          <PaperHeading label={label} />
        </label>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      {expansionActionButtons && (
        <Fragment>
          <Divider />
          <ExpansionPanelActions>
            {expansionActionButtons.map((button) => (
              <Button
                size="small"
                key={button.buttonLabel}
                variant={button.variant ? button.variant : "text"}
                color={button.color ? button.color : "primary"}
                onClick={button.action}
              >
                {button.buttonLabel}
              </Button>
            ))}
          </ExpansionPanelActions>
        </Fragment>
      )}
    </ExpansionPanel>
  );
};
export default MUIExpansionPanel;
