import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import { BrowserRouter } from "react-router-dom";
import routes from "./routes/routes";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { config } from "./clientApp/configAdal";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom.scss";
// import "./css/grundfos.css"; Use only with DefaultLayout

import "./App.scss";
import "toastr/toastr.scss";

import { RootProvider } from "./provider/RootProvider";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faUser);
library.add(faSignOutAlt);

function App() {
  const pcaAlert = new PublicClientApplication(config as Configuration);

  return (
    <MsalProvider instance={pcaAlert}>
      <RootProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading....</div>}>
            {renderRoutes(routes)}
          </Suspense>
        </BrowserRouter>
      </RootProvider>
    </MsalProvider>
  );
}

export default App;
