import { ICheckedItemList } from "components/List/type";
import { ILabelValue } from "components/type";

export const getOptions = (
  items: any[],
  id: string,
  label: string
): ILabelValue[] => {
  const data: ILabelValue[] = [];

  if (items) {
    items.forEach((i) => {
      data.push({
        label: i[label],
        value: i[id],
      });
    });
  }

  return data;
};

export const getCheckedOptions = (
  items: any[],
  id: string,
  label: string,
  checked = false
): ICheckedItemList[] => {
  const data: ICheckedItemList[] = [];

  if (items) {
    items.forEach((i) => {
      data.push({
        label: i[label],
        value: i[id],
        checked,
      });
    });
  }

  return data;
};

export const getAllChecked = (items: any[], field = "") => {
  const checkedItems = items.filter((item) => item.checked === true);
  if (field !== "") {
    return checkedItems.map((item) => item[field]);
  }
  return checkedItems;
};

export const hierarchyContainer = (data: {}) => {
  return { hierarchy: data };
};

export const validateEmail = (email: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const sumDigits = (value: number) => {
  let sum = 0;
  while (value > 0) {
    sum += parseInt(`${value % 10}`);
    value = parseInt(`${value / 10}`);
  }
  return sum;
};

export const validateMACAddress = (address: string): boolean => {
  if (
    address.match(
      /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}[.]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{4}[-]){2}([0-9A-Fa-f]{4})$|^[0-9A-Fa-f]{12}$/i
    )
  ) {
    return true;
  }
  return false;
};

export const validateIMEIAddress = (address: string) => {
  if (address.length !== 15) {
    return false;
  }

  let sum = 0;
  let addressInt = parseInt(address);
  for (let i = address.length; i >= 1; i--) {
    let currentValue = parseInt(`${addressInt % 10}`);

    // Multiple itself every other digit
    if (i % 2 == 0) {
      currentValue = 2 * currentValue;
    }

    // Find sum of the digits
    sum += sumDigits(currentValue);
    addressInt /= 10;
  }
  return sum % 10 == 0;
};

const validateAssignedIdIdentifierAndLength = (assignedId: string): boolean => {
  const identifierAndLengthSubstring = assignedId.substring(0, 4);

  if (identifierAndLengthSubstring.match(/^(0[1-3])(0[0-9aA])$/i)) {
    return true;
  }
  return false;
};

const validateAssignedIdAddress = (assignedId: string) => {
  const lengthSubstring = assignedId.substring(2, 4);
  const length = parseInt(lengthSubstring, 16);

  const addressSubstring = assignedId.substring(4);

  if (length * 2 === addressSubstring.length) {
    if (addressSubstring.match(/^[0-9a-fA-F]/)) {
      return true;
    }
  }

  return false;
};

export const validateAssignedId = (assignedId: string): boolean => {
  if (assignedId.length < 6) {
    return false;
  }

  return (
    validateAssignedIdIdentifierAndLength(assignedId) &&
    validateAssignedIdAddress(assignedId)
  );
};

export const validateRangeLatitude = (value: string) => {
  if (value) {
    if (parseFloat(value) <= 90 && parseFloat(value) >= -90) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const validateRangeLongitude = (value: string) => {
  if (value) {
    if (parseFloat(value) <= 180 && parseFloat(value) >= -180) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const validateIsNaN = (value: string) => {
  if (value) {
    const checkNumber = Number(value);

    if (!isNaN(checkNumber)) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const toMonth = (m: number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (m > 11 || m < 0) {
    return months[0];
  }

  return months[m];
};

export const prependZeroToSingleDigits = (d: number | string) => {
  if (d < 10) {
    return `0${d}`;
  }
  return `${d}`;
};

const decimalPlaces = (num: number): number => {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
  );
};

export const generateUUID = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const isValidGuid = (id = "") => {
  const pattern = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );

  return pattern.test(id);
};

const getDoubleDigitFormatValue = (val: number = 0) => {
  return `${val < 10 ? "0" : ""}${val}`;
};

export const getCurrentDate = (date = new Date()) => {
  return `${date.getFullYear()}-${getDoubleDigitFormatValue(date.getMonth() + 1)}-${getDoubleDigitFormatValue(
    date.getDate()
  )}T${getDoubleDigitFormatValue(date.getHours())}:${getDoubleDigitFormatValue(
    date.getMinutes()
  )}:${getDoubleDigitFormatValue(
    date.getSeconds()
  )}.${date.getMilliseconds()}Z`;
};

export const isNullOrEmpty = (s: string = "") => {
  if (s === "" || s === null) {
    return true;
  }

  return false;
};

export const getTokenOid = (tokenClaims: {} | undefined) => {
  if (tokenClaims) {
    const claims = tokenClaims as { oid: string };
    return claims.oid;
  }

  return "";
};
