import React, { useState, createContext } from "react";

export const SpinnerContext = createContext<any>(null);

export const SpinnerProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  return (
    <SpinnerContext.Provider value={[isSpinnerVisible, setIsSpinnerVisible]}>
      {children}
    </SpinnerContext.Provider>
  );
};
