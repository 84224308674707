import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";

import {
  AcknowledgeAlert,
  getAlertsLogsTable,
} from "../../../actions/genericActions";
import { useMsal, useAccount } from "@azure/msal-react";

import { SpinnerContext } from "provider/SpinnerProvider";
import { ModalContext } from "provider/ModalProvider";
import { GenericContext } from "provider/GenericProvider";

import { IAcknowledgeBody } from "components/type";
import { generateUUID, getCurrentDate, getTokenOid } from "utils/utils";

const CreateCommentsModal = (props: any) => {
  const { instance, accounts } = useMsal();
  const adAccount = useAccount(accounts[0] || {});
  const getOid = () => getTokenOid(adAccount?.idTokenClaims) ?? "";
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { acknowledgeModalContext } = useContext(ModalContext);
  const { loadCommentsContext } = useContext(GenericContext);
  const [, setShowCommentsModal] = acknowledgeModalContext;
  const [, setLoadComments] = loadCommentsContext;

  const onSubmit = (data: any): void => {
    const sendData: IAcknowledgeBody = {
      correlationId: generateUUID(),
      alertCorrelationId: props.alertCorrelationId,
      acknowledgeBy: getOid(),
      acknowledgeFeedback: data.comments,
      acknowledgeWhen: getCurrentDate(),
    };

    setIsSpinnerVisible(true);
    AcknowledgeAlert(sendData, instance).then((e) => {
      setIsSpinnerVisible(false);
      setShowCommentsModal(false);
      props.onSubmit();
    });
    getAlertsLogsTable(
      `${props.alertCorrelationId}/${props.accountId}`,
      instance
    ).then((e: any) => {
      setLoadComments(e[0].data);
    });
  };
  const { register, handleSubmit } = useForm();
  return (
    <Form id="submitAcknowledge-form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="comments"
        label="Write your comment here"
        multiline={true}
        variant="outlined"
        rows={5}
        rowsMax={5}
        inputProps={{ maxLength: 255 }}
        inputRef={register}
      />
    </Form>
  );
};
export default CreateCommentsModal;
