import React, { useState, useEffect } from "react";
import Denied from "pages/Denied";
import Loader from "../components/Loader";

import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

const PrivateRoute = ({
  component,
  props,
}: {
  component: any;
  props: any;
}): JSX.Element => {
  const Component = component;
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [loading, setLoading] = useState(true);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setLoading(true);
    if (account && isAuthenticated) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return <Loader />;
  } else {
    if (account && isAuthenticated !== undefined && isAuthenticated) {
      return <Component {...props} />;
    } else {
      return <Denied />;
    }
  }
};

export default PrivateRoute;
