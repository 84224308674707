import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullTimeZones } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";
import { useMsal } from "@azure/msal-react";

export const TimeZonesContext = createContext<any>(null);

export const TimeZonesProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const { instance } = useMsal();
  const [timeZones, setTimeZones] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getTimeZones = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(timeZones) || dateTimeRefresh < dateTime) {
      await pullTimeZones(instance).then((timeZones) => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setTimeZones(timeZones);
      });
    }
  };

  return (
    <TimeZonesContext.Provider value={[timeZones, getTimeZones]}>
      {children}
    </TimeZonesContext.Provider>
  );
};
