import React, { Fragment, useEffect, useState, useContext } from "react";
import { ModalContext } from "provider/ModalProvider";
import { useParams } from "react-router-dom";

import {
  Error,
  AccessTime,
  LocationOn,
  Person,
  AssignmentTurnedIn,
  SettingsInputComponent,
} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import DialogModal from "components/DialogModal";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import ExpansionPanel from "components/ExpansionPanel";
import PageHeader from "components/PageHeader";
import PageSubHeader from "components/PageSubHeader";
import SubContainer from "components/SubContainer";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import PageSubHeaderDetailItem from "components/PageSubHeaderDetailItem";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import EnhancedTable from "components/EnhancedTable";

import { mainStyle } from "./styles";
import CreateCommentsModalForm from "../CreateCommentsModal";
import { GenericContext } from "provider/GenericProvider";
import { useAccount, useMsal } from "@azure/msal-react";

import {
  getAlertLogs,
  getAlertsLogsTable,
} from "../../../actions/genericActions";
import { IAlertLogParams } from "components/type";
import { getTokenOid } from "utils/utils";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    minWidth: 75,
    minHeight: 75,
  },
  subIconStyle: {
    minWidth: 30,
    minHeight: 30,
    marginRight: "5px",
    marginLeft: "15px",
  },
  title: {
    color: "#f2f2f2",
    fontFamily: "GrundfosTheSans, Arial, sans-serif",
    letterSpacing: "2px",
    margin: 0,
    marginLeft: "-2px",
  },
  subTitle: {
    color: theme.palette.primary.light,
    fontSize: "8px",
    textTransform: "uppercase",
    textDecoration: "underline",
    margin: 0,
  },
  leftPanelHeader: {
    flexGrow: 1,
  },
  label: {
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontStyle: "italic",
    marginRight: theme.spacing(0.5),
  },
  value: {
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
  },
  rightPanelItemContent: {
    margin: theme.spacing(2),
  },
  expansion: {
    bottom: "25px",
    display: "block",
    position: "fixed",
    width: "100%",
  },
  root: {
    display: "flex",
  },
}));

const headCells: IHeadCell[] = [
  {
    id: "alertAcknowledgeWhen",
    disablePadding: false,
    label: "Acknowledge date",
    fieldName: "alertAcknowledgeWhen",
  },
  {
    id: "alertAcknowledgedBy",
    disablePadding: false,
    label: "Acknowledge by",
    fieldName: "alertAcknowledgedBy",
  },
  {
    id: "remarks",
    disablePadding: false,
    label: "Comments",
    fieldName: "alertAcknowledgeFeedback",
  },
];

const Acknowledge = () => {
  const { instance, accounts } = useMsal();
  const adAccount = useAccount(accounts[0] || {});
  const getOid = () => getTokenOid(adAccount?.idTokenClaims) ?? "";
  const [searchKey, setSearchKey] = useState<string>("");
  const [isAcknowledgeDisabled, setIsAcknowledgeDisabled] = useState(true);
  const [shouldReload, setShouldReload] = useState(false);
  const { acknowledgeModalContext } = useContext(ModalContext);
  const { loadCommentsContext } = useContext(GenericContext);
  const [showCommentsModal, setShowCommentsModal] = acknowledgeModalContext;
  const [loadComments, setLoadComments] = loadCommentsContext;
  const { alertCorrelationId, accountId } =
    useParams<{
      alertCorrelationId: string;
      accountId: string;
    }>();
  const classes = useStyles();
  const date = new Date();

  const handleOk = (): void => {
    return;
  };

  const getUserAcknowledgementsList = (
    result: {
      acknowledgements: { alertAcknowledgedBy: { value: string } }[];
    } | null
  ): string[] => {
    if (result?.acknowledgements) {
      return result?.acknowledgements.map((x) => x.alertAcknowledgedBy.value);
    }

    return [];
  };

  const alertLogParam: IAlertLogParams = {
    alertCorrelationId: alertCorrelationId,
    accountId: accountId,
  };

  useEffect(() => {
    getAlertLogs(alertLogParam, instance).then(
      (result: {
        acknowledgements: { alertAcknowledgedBy: { value: string } }[];
      }) => {
        const usersList = getUserAcknowledgementsList(result);

        // TODO: JEG
        if (adAccount) {
          setIsAcknowledgeDisabled(
            usersList.includes(getOid())
          );
        }
      }
    );
  }, [alertCorrelationId, shouldReload, adAccount]);

  useEffect(() => {
    getAlertsLogsTable(
      `${alertLogParam.alertCorrelationId}/${alertLogParam.accountId}`,
      instance
    ).then((e: any) => {
      setLoadComments(e[0].data);
    });
  }, [showCommentsModal]);

  return (
    <div>
      <div style={mainStyle}>
        <PageHeader
          title="Notification"
          icon={<Error className={classes.iconStyle} color="secondary" />}
          details={
            <Fragment>
              <PageHeaderDetailItem label="Alert Code" value="168" />
            </Fragment>
          }
        ></PageHeader>

        <SubContainer
          details={
            <PageSubHeader
              title={`${
                date.getDate() + 1
              }/ ${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${
                date.getUTCMinutes() < 10 ? "0" : ""
              }${date.getUTCMinutes()}`}
              icon={
                <AccessTime className={classes.subIconStyle} color="action" />
              }
              details={
                <Fragment>
                  <PageSubHeaderDetailItem
                    label="On Going for 53min"
                    value=""
                  />
                </Fragment>
              }
            ></PageSubHeader>
          }
        >
          <PageSubHeader
            title="Bandegard"
            icon={
              <SettingsInputComponent
                className={classes.subIconStyle}
                color="action"
              />
            }
            details={
              <Fragment>
                <PageSubHeaderDetailItem label="Skejby Pit" value="" />
              </Fragment>
            }
          ></PageSubHeader>
          <PageSubHeader
            title="Skejby Vaenge 103 A"
            icon={
              <LocationOn className={classes.subIconStyle} color="action" />
            }
            details={
              <Fragment>
                <PageSubHeaderDetailItem label="Aarhus N, DK" value="" />
              </Fragment>
            }
          ></PageSubHeader>

          <PageSubHeader
            title="Rene Andersen"
            icon={<Person className={classes.subIconStyle} color="action" />}
            details={
              <Fragment>
                <PageSubHeaderDetailItem label="Delegated to" value="" />
              </Fragment>
            }
          ></PageSubHeader>
        </SubContainer>
        <Divider orientation="horizontal" light={true} />

        <PageSubHeader
          title="What is the problem"
          details={
            <Fragment>
              <PageSubHeaderDetailItem
                label="Large amount of imploding bubbles or cavities may cause damage to the pump impeller and housing."
                value=""
              />
            </Fragment>
          }
        ></PageSubHeader>

        <PageSubHeader
          title="What is the risk"
          details={
            <Fragment>
              <PageSubHeaderDetailItem
                label="There is a likelihood the the hydraulic will fail, shaft seal leakage, reduced bearing lifetime and reduced efficiency. The pump may not be able to deliver the right flow and pressure."
                value=""
              />
            </Fragment>
          }
        ></PageSubHeader>

        <PageSubHeader
          title="How to fix"
          details={
            <Fragment>
              <PageSubHeaderDetailItem
                label="There is a likelihood the the hydraulic will fail, shaft seal leakage, reduced bearing lifetime and reduced efficiency. The pump may not be able to deliver the right flow and pressure."
                value=""
              />
            </Fragment>
          }
        ></PageSubHeader>
      </div>
      <div className={classes.expansion}>
        <ExpansionPanel label="Timeline" defaultExpanded={false}>
          {/* to be used once the API is ready for paging */}
          {/*    <EnhancedPaginatedTable
            getData={getAlertLogs}
            headCells={headCells}
            query={`${alertLogParam.alertCorrelationId}/${alertLogParam.accountId}`}
          /> */}

          <EnhancedTable headCells={headCells} data={loadComments} />
        </ExpansionPanel>
      </div>

      <FloatingActionButtonList>
        <FloatingActionButton
          type="submit"
          onClick={(): void => setShowCommentsModal(true)}
          disabled={isAcknowledgeDisabled}
        >
          <AssignmentTurnedIn />
          Acknowledge
        </FloatingActionButton>
      </FloatingActionButtonList>

      <DialogModal
        showCancelButton
        formId={"submitAcknowledge-form"}
        title={`Acknowledge event`}
        content={
          <CreateCommentsModalForm
            alertCorrelationId={alertCorrelationId}
            accountId={accountId}
            onSubmit={() => setShouldReload(!shouldReload)}
          />
        }
        showModal={showCommentsModal}
        onOk={(): void => {
          handleOk();
        }}
        onHide={(): void => {
          setShowCommentsModal(false);
        }}
        confirmButtonLabel="Acknowledge"
        cancelButtonLabel="Cancel"
      />
    </div>
  );
};

export default Acknowledge;
