import { Team } from "../types";

export interface InstallationDevice_Device {
  deviceComputedHash: string;
  deviceTwinId: string;
  deviceName: string;
  deviceType: string;
}

export interface InstallationDevice {
  installationName: string;
  installationId: string;
  devices: InstallationDevice_Device[];
}

export class TeamsContainer {
  private _teams: Team[] = [];

  get teams() {
    return this._teams;
  }
  set teams(value) {
    this._teams = value;
  }

  constructor(teams: Team[] = []) {
    this._teams = teams;
  }

  public hasNoDuplicateNames(teamName = ""): boolean {
    return this._teams.findIndex((t) => t.teamName === teamName) === -1;
  }

  public length() {
    return this._teams.length;
  }

  public getTeam(teamId = ""): Team | undefined {
    return this._teams.find((t) => t.teamId === teamId);
  }
}
