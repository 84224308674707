import React, { createContext } from "react";

export const CodeContext = createContext<any>([{}, () => {}]);

export const CodeProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  
  return (
    <CodeContext.Provider value="">
      {children}
    </CodeContext.Provider>
  );
};