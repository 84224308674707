import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { ILabelValue } from "components/type";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontStyle: "italic",
    marginRight: theme.spacing(0.5),
  },
  value: {
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
  },
}));

const PageHeaderDetailItem = ({ label, value }: ILabelValue): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {label && (
        <Typography component="h6" className={classes.label}>
          {label}:
        </Typography>
      )}
      {value && (
        <Typography component="h6" className={classes.value}>
          {value}
        </Typography>
      )}
    </Box>
  );
};
export default PageHeaderDetailItem;
