import {
  apiUrl,
  policyApiUrl,
  teamsApiUrl,
  notificationApiUrl,
  alertingApiUrl,
} from "clientApp/configAdal";

export const endPoints = {
  applications: `${apiUrl}/api/v1/Applications`,
  configurationUsers: `${apiUrl}/api/v1/ConfigurationUsers`,
  countries: `${apiUrl}/api/v1/Countries`,
  dateFormat: `${apiUrl}/api/v1/DateFormats`,
  devices: `${apiUrl}/api/v1/Devices`,
  digitalOfferings: `${apiUrl}/api/v1/DigitalOfferings`,
  hierarchies: `${apiUrl}/api/v1/Hierarchies`,
  installations: `${apiUrl}/api/v1/Installations`,
  languages: `${apiUrl}/api/v1/Languages`,
  serviceGroups: `${apiUrl}/api/v1/ServiceGroups`,
  timeFormats: `${apiUrl}/api/v1/TimeFormats`,
  types: `${apiUrl}/api/v1/Types`,
  timeZones: `${apiUrl}/api/v1/TimeZones`,
  unitSystems: `${apiUrl}/api/v1/UnitSystems`,
  deviceCharacteristics: `${apiUrl}/api/v1/DeviceCharacteristics`,
  IOTSystems: `${apiUrl}/api/v1/IOTSystems`,
  userHierarchies: `${apiUrl}/api/v1/UserHierarchies`,
  subscriptions: `${apiUrl}/api/v1/Subscriptions`,
  azureAd: `${apiUrl}/api/v1/AzureAd`,
  globalUsers: `${apiUrl}/api/v1/globalusers`,
  externalUser: `${apiUrl}/api/v1/externalusers`,
  policies: `${apiUrl}/api/v1/Policies`,
  teamsQuery: `${teamsApiUrl}/api/v1/Query`,
  teamsCommand: `${teamsApiUrl}/api/v1/Commands`,
  AcknowledgeAlert: `${notificationApiUrl}/api/v1/Acknowledgements/acknowledgeAlert`,
  AlertLogs: `${alertingApiUrl}/api/v2/alerts`,
};

export const policyEndPoints = {
  permission: `${policyApiUrl}/api/v1/Permission`,
  user: `${policyApiUrl}/api/v1/User`,
};
