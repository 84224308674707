import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router";
import MainBarDashboard from "../../../components/MainBarDashboard";
import PageHeader from "components/PageHeader";
import { TeamsPath } from "routes/paths";
import { DragDropContext } from "react-beautiful-dnd";
import { changeTeamMember } from "actions/teamsActions";
import { useMsal } from "@azure/msal-react";
import UnassignedUsersPanel from "./UnassignedUsersPanel";
import { Grid } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import TeamsPanel from "./TeamsPanel/TeamsPanel";
import { Team, User } from "./types";
import { SpinnerContext } from "provider/SpinnerProvider";
import { generateUUID } from "utils/utils";
import {
  useAccount,
  useAccountUsers,
  useTeamsByAccountId,
} from "../../../hooks/hooks";

interface Data {
  users: User[];
  teams: Team[];
}

const UNASSIGNED_USERS_TEAM_ID = "unassignedUsersPanelId0";

const AssignUsersToTeams = () => {
  const { instance } = useMsal();
  const breadcrumbs = [
    { label: "Teams", href: TeamsPath },
    { label: "Assign Users To Teams" },
  ];
  const { accountId } = useParams<{ accountId: string }>();
  const [unassignedUsers, setUnassignedUsers] = useState<User[] | undefined>();
  const [data, setData] = useState<Data>({ users: [], teams: [] });
  const [teamsUpdated, setTeamsUpdated] = useState<boolean>(false);
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [isAccountCompleted, setIsAccountCompleted] =
    useState<boolean>(false);
  const [isAccountUsersCompleted, setIsAccountUsersCompleted] =
    useState<boolean>(false);
  const [isTeamsByAccountIdCompleted, setIsTeamsByAccountIdCompleted] =
    useState<boolean>(false);
  const [isDataLoadingComplete, setIsDataLoadingComplete] =
    useState<boolean>(false);

  const account = useAccount(accountId, teamsUpdated, () =>
    setIsAccountCompleted(true)
  );
  const accountUsers = useAccountUsers(accountId, teamsUpdated, () =>
    setIsAccountUsersCompleted(true)
  );
  const teams = useTeamsByAccountId(accountId, teamsUpdated, () =>
    setIsTeamsByAccountIdCompleted(true)
  );

  const reset = () => {
    setIsSpinnerVisible(true);
    setIsAccountCompleted(false);
    setIsAccountUsersCompleted(false);
    setIsTeamsByAccountIdCompleted(false);
    setIsDataLoadingComplete(false);
  };

  useEffect(() => {
    setIsSpinnerVisible(true);
    if (
      isDataLoadingComplete &&
      isAccountCompleted &&
      isAccountUsersCompleted &&
      isTeamsByAccountIdCompleted
    ) {
      setIsSpinnerVisible(false);
    }
  }, [
    isDataLoadingComplete,
    isAccountCompleted,
    isAccountUsersCompleted,
    isTeamsByAccountIdCompleted,
  ]);

  useEffect(() => {
    // Unassigned users
    if (accountUsers && isTeamsByAccountIdCompleted) {
      const unassignedAccountUsers: User[] = [];

      accountUsers.forEach((u) => {
        // TODO: update this line when escalation is implemented
        u.escalationQueueNumber = 0;

        u.draggableId = generateUUID();
        unassignedAccountUsers.push(u);
      });

      setUnassignedUsers(unassignedAccountUsers);
    }
  }, [accountUsers, isTeamsByAccountIdCompleted]);

  useEffect(() => {
    if (unassignedUsers && isTeamsByAccountIdCompleted) {
      const t = teams ? [...teams] : [];
      const teamsWithUnassignedUsersAsATeam: Team[] = [
        ...t,
        {
          teamId: UNASSIGNED_USERS_TEAM_ID,
          accountId: accountId || "",
          users: unassignedUsers,
        },
      ];

      setData({
        users: unassignedUsers,
        teams: teamsWithUnassignedUsersAsATeam,
      });

      setIsDataLoadingComplete(true);
    }
  }, [unassignedUsers, isTeamsByAccountIdCompleted]);

  const shouldDoNothing = (destination: any, source: any, draggableId = "") => {
    if (!destination) {
      return true;
    }

    const finish = data.teams.find((x) => x.teamId === destination.droppableId);
    if (finish) {
      const user = data.users.find((u: User) => u.draggableId === draggableId);
      if (user) {
        const result = finish.users.findIndex((u) => u.userId === user.userId);
        if (result > -1) {
          return true;
        }
      }
    }

    return (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    );
  };

  const saveTeamMemberChange = (team: Team) => {
    if (team.teamId === UNASSIGNED_USERS_TEAM_ID) {
      return;
    }

    setIsSpinnerVisible(true);
    changeTeamMember(team, instance).then(() => {
      reset();
      setTeamsUpdated(!teamsUpdated);
    });
  };

  const onDragEnd = (result: any, provided: any) => {
    const { destination, source, draggableId } = result;

    if (shouldDoNothing(destination, source, draggableId)) {
      return;
    }

    const start = data.teams.find((x) => x.teamId === source.droppableId);
    const finish = data.teams.find((x) => x.teamId === destination.droppableId);

    const startUsers = start ? [...start.users] : [];
    startUsers?.splice(source.index, 1);
    const newStart: Team = {
      teamId: start?.teamId || "",
      teamName: start?.teamName || "",
      accountId: start?.accountId || "",
      teamDescription: start?.teamDescription || "",
      escalation: start?.escalation,
      alertCode: start?.alertCode,
      users: startUsers || [],
    };

    const finishUsers = finish ? [...finish.users] : [];
    const itemToAdd = start?.users?.find((x) => x.draggableId === draggableId);
    if (itemToAdd) {
      itemToAdd.draggableId = generateUUID();
      finishUsers?.splice(destination.index, 0, itemToAdd);
    }

    const newFinish: Team = {
      teamId: finish?.teamId || "",
      teamName: finish?.teamName || "",
      accountId: finish?.accountId || "",
      teamDescription: finish?.teamDescription || "",
      escalation: finish?.escalation,
      alertCode: finish?.alertCode,
      users: finishUsers || [],
    };

    const newTeams = [...data?.teams];
    const startToDelete = newTeams.find((x) => x.teamId === newStart.teamId);
    const finishToDelete = newTeams.find((x) => x.teamId === newFinish.teamId);
    if (startToDelete && finishToDelete) {
      let i = newTeams.indexOf(startToDelete);
      newTeams.splice(i, 1);
      newTeams.splice(i, 0, newStart);

      i = newTeams.indexOf(finishToDelete);
      newTeams.splice(i, 1);
      newTeams.splice(i, 0, newFinish);

      setData({
        ...data,
        teams: newTeams,
      });

      saveTeamMemberChange(newFinish);
    }
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={account ? account.accountName : ""}
        subTitle={accountId ? "Account" : ""}
      >
        {" "}
      </PageHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <UnassignedUsersPanel
              title="Users"
              id={UNASSIGNED_USERS_TEAM_ID}
              users={
                data.teams.find((x) => x.teamId === UNASSIGNED_USERS_TEAM_ID)
                  ?.users || []
              }
            />
          </Grid>

          <Grid item xs={1}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              alignContent="center"
              style={{ height: "100%" }}
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <SyncAltIcon />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <TeamsPanel
              teams={data.teams.filter(
                (x) => x.teamId !== UNASSIGNED_USERS_TEAM_ID
              )}
              account={account}
              onTeamsModified={() => {
                reset();
                setTeamsUpdated(!teamsUpdated);
              }}
            />
          </Grid>
        </Grid>
      </DragDropContext>
    </MainBarDashboard>
  );
};

export default AssignUsersToTeams;
